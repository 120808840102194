<template>
  <div v-if="noticia">
    <div class="card">
      <h3 class="card-title">Editar noticia</h3>
      <div class="cols2">
        <pd-input
          type="text"
          id="titulo"
          v-model="noticia.titulo"
          placeholder="Titulo"
          label="Título"
        ></pd-input>
        <pd-input
          id="tipo"
          placeholder="Tipo"
          :select="true"
          label="Tipo"
          v-model="noticia.tipo"
        >
          <template slot="options">
            <option :value="null">Seleccione un tipo</option>
            <option
              v-for="tipoNoticia in tipos"
              :key="tipoNoticia.value"
              :value="tipoNoticia.value"
            >
              {{ tipoNoticia.text }}
            </option>
          </template>
        </pd-input>
        <pd-input
          v-model="noticia.desde"
          id="desde"
          label="Desde"
          placeholder="Desde"
          type="date"
        ></pd-input>
        <pd-input
          v-model="noticia.hasta"
          id="desde"
          label="Hasta"
          placeholder="Hasta"
          type="date"
        ></pd-input>
      </div>
      <pd-input
        type="file"
        id="archivo"
        placeholder="Archivo"
        label="PDF"
      ></pd-input>
      <div class="form-group my-1">
        <label class="form-label">Contenido</label>
        <quill-editor
          v-model="noticia.contenido"
          :options="editorOption"
        ></quill-editor>
      </div>
      <div class="form-group my-1">
        <label class="form-label">Estado</label>
        <select id="publicado" class="form-control" v-model="noticia.publicado">
          <option :value="1">Publicado</option>
          <option :value="0">Sin publicar</option>
        </select>
      </div>
      <button class="btn btn-primary" @click="save">Guardar</button>
    </div>
    <div class="noticias">
      <div class="card">
        <h4>Previsualización de cartelera</h4>
        <hr />
        <div class="noticias">
          <div class="card" :class="classNoticia">
            <h4 class="card-title">{{ noticia.titulo }}</h4>
          </div>
        </div>
        <h4 class="mt-2">Previsualización de noticia completa</h4>
        <hr />
        <div>
          <h2 class="card-title text-center mt-2 mb-2">{{ noticia.titulo }}</h2>
          <div class="d-flex" v-if="noticia.archivo">
            <embed
              :src="'data:application/pdf;base64,' + noticia.contenido_archivo"
              style="width: 100%; height: 29.7cm"
              frameborder="0"
            />
          </div>
          <div style="padding: 0 5px" v-html="noticia.contenido" v-else></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import { NoticiasServices } from "../services/NoticiasServices";
import Swal from "sweetalert2";
export default {
  name: "EditarNoticia",
  data: () => {
    return {
      noticia: null,
      editorOption: {
        theme: "snow",
      },
      tipos: [
        { value: "remember", text: "Recordatorio" },
        { value: "novedad", text: "Novedad" },
        { value: "felicitaciones", text: "Felicitación" },
        { value: "luto", text: "Luto" },
        { value: "interes", text: "Información de interés" },
        { value: "formulario", text: "Formulario" },
        { value: "procedimiento", text: "Procedimiento" },
      ],
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    classNoticia() {
      let clase = "";
      if (this.noticia.tipo) {
        clase = `card-${this.noticia.tipo}`;
      }
      return clase;
    },
  },
  created() {
    const noticiaId = this.$route.params.id;
    NoticiasServices.api
      .find(noticiaId)
      .then((response) => {
        this.noticia = response.noticia;
      })
      .catch((error) => {
        Swal.fire("Error", `${error}`, "error");
      });
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append("titulo", this.noticia.titulo);
      formData.append("contenido", this.noticia.contenido);
      const archivo = document.querySelector("input#archivo");
      formData.append("archivo", archivo.files[0]);
      formData.append("tipo", this.noticia.tipo);
      formData.append("desde", this.noticia.desde);
      formData.append("hasta", this.noticia.hasta);
      formData.append("publicado", this.noticia.publicado);
      NoticiasServices.api
        .put(this.noticia.id, formData)
        .then((response) => {
          if (response.success) {
            Swal.fire("Éxito", "Se guardó correcamente", "success");
            this.$router.push({ name: "ListarNoticias" });
          } else {
            Swal.fire("Error", "No se pudo guardar", "error");
          }
        })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    },
  },
};
</script>